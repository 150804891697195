import { createGlobalStyle } from "styled-components";
import Router from "./Router";
import { darkTheme, lightTheme } from "./theme";
import { ThemeProvider } from "styled-components";
import { ReactQueryDevtools } from 'react-query/devtools';
import { useState } from "react";
import styled from "styled-components";
import GlobalStyle from './globaclStyle'

// const ThemeBtn = styled.button`
//   width: 60px;
//   height: 40px;
//   text-align: center;
//   text-transform: uppercase;
//   font-size: 12px;
//   font-weight: 400;
//   background-color: #2f3640;
//   padding: 7px 0px;
//   border-radius: 10px;
//   color: ${(props) => props.theme.textColor};
// `;

// const GlobalStyle = createGlobalStyle`
// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, menu, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed,
// figure, figcaption, footer, header, hgroup,
// main, menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure,
// footer, header, hgroup, main, menu, nav, section {
//   display: block;
// }
// /* HTML5 hidden-attribute fix for newer browsers */
// *[hidden] {
//     display: none;
// }

// menu, ol, ul {
//   list-style: none;
// }
// blockquote, q {
//   quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
//   content: '';
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }
// * {
//   box-sizing: border-box;
// }
// body {
//   font-family: 'Source Sans Pro', sans-serif;
//   background-color:${(props) => props.theme.bgColor};
//   color:${(props) => props.theme.textColor}
// }
// a {
//   text-decoration:none;
//   color: inherit;
// }
// `;

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Router toggleTheme={toggleTheme} />
      <ReactQueryDevtools initialIsOpen={true} />
    </ThemeProvider>
  );
}

export default App;
